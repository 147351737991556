import { ref } from 'vue';
import { Loader } from '@googlemaps/js-api-loader';

import Sentry from '@util/sentry';

export function useGoogleMapsApi(gMapsOptions = {}) {
  const { libraries = ['places'], ...otherGMapsOptions } = gMapsOptions;
  const sentry = Sentry.getInstance();
  const googleMapsApi = ref(null);

  const getGMapsApi = async () => {
    if (googleMapsApi.value) {
      return Promise.resolve(googleMapsApi.value);
    }

    const loader = new Loader({
      apiKey: IPSO_GLOBALS.GOOGLE_MAPS_API_JS_KEY,
      version: 'quarterly', // fixed version is too risky as they disappear over time, completely breaking the map
      libraries,
      ...otherGMapsOptions,
    });

    try {
      const google = await loader.load();
      googleMapsApi.value = google;
      return google;
    } catch (e) {
      console.error(e);
      sentry.captureException(e);
    }
  };

  return { googleMapsApi, getGMapsApi };
}

<template>
  <div>
    <modal
      v-for="modal in modals"
      :key="modal.id"
      :is-open="modal.isOpen"
      :fit-to-content="modal.fitToContent"
      :container-class="modal.containerClass"
      @has-closed="closeModal(modal.id)"
    >
      <template #header>
        <span>{{ modal.title }}</span>
      </template>
      <template #default>
        <div
          v-if="modal.isLoading"
          class="c-loader"
          aria-label="Chargement en cours"
        />
        <div v-html="modal.content" />
      </template>
    </modal>
  </div>
</template>

<script>
import Api from '@api/index';

import Modal from '@style-guide/components/modal/Modal.vue';

export default {
  components: {
    Modal,
  },

  data() {
    return {
      modals: {},
    };
  },

  mounted() {
    const modalButtons = document.querySelectorAll('[data-ipso-ajax-modal]');

    /**
     * each modal button looks like
     * <button data-ipso-ajax-modal data-ipso-ajax-modal-title="modal title" data-ipso-ajax-modal-url="ajax-url">ajax modal</button>
     */
    modalButtons.forEach((modalButton, index) => {
      const title = modalButton.dataset.ipsoAjaxModalTitle;
      const url = modalButton.dataset.ipsoAjaxModalUrl;
      const fitToContent = modalButton.dataset.ipsoAjaxModalFitToContent;
      const containerClass = modalButton.dataset.ipsoAjaxModalContainerClass;

      const props = {
        title,
        id: index,
        isOpen: false,
        isLoading: true,
        fitToContent,
        containerClass,
      };

      this.modals[index] = props;

      modalButton.addEventListener('click', () => {
        this.openAndLoadModal(index, url);
      });
    });
  },

  methods: {
    openAndLoadModal: async function (id, url) {
      this.modals[id].isOpen = true;

      if (!this.modals[id].content) {
        const [result] = await Api.get({
          path: url,
          params: { is_ajax: true },
        });
        // FIXME : check errors ?
        this.modals[id].content = result;
        this.modals[id].isLoading = false;
      }
    },

    closeModal(id) {
      this.modals[id].isOpen = false;
    },
  },
};
</script>

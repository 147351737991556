import $ from 'jquery';

export const FIELD_AUTOFOCUS_SELECTOR = 'data-ipso-field-autofocus';

export default () => {
  const $fields = $(`[${FIELD_AUTOFOCUS_SELECTOR}]`);
  if (!$fields.length) {
    return false;
  }

  $fields.each((i, el) => new FieldAutoFocus(el));
};

export class FieldAutoFocus {
  constructor(el) {
    this.$input = $(el);
    if (this.$input.attr('type') == 'text' && this.$input.attr('pattern')) {
      this.$input.keyup((event) => {
        this.autofocus(event, this.$input.val(), this.$input.attr('pattern'));
      });
    }
  }

  autofocus = function (event, val, pattern) {
    if (event.keyCode == 9) {
      return;
    }
    const re = new RegExp(pattern);
    if (re.test(val)) {
      this.$input.next().val('').focus();
    } else {
      this.$input.val('');
    }
  };
}

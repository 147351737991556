<template>
  <svg class="inline-block" :class="defaultCss">
    <use :xlink:href="iconSpriteUrl"></use>
  </svg>
</template>

<script>
import defaultCssClass from '@util/vue-default-css-class';

export default {
  props: {
    name: {
      type: String,
    },

    path: {
      type: String,
      default: 'svg/icon-sprite.svg',
    },
  },

  computed: {
    defaultCss() {
      return defaultCssClass(this, 'w-8 h-8');
    },

    iconSpriteUrl() {
      const PROJECT_RELEASE_VERSION =
        window.IPSO_GLOBALS?.PROJECT_RELEASE_VERSION;

      return `/static/${this.path}?v=${PROJECT_RELEASE_VERSION}#${this.name}`;
    },
  },
};
</script>

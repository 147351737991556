import $ from 'jquery';
import gsap from 'gsap';

const BOX_TITLE_SELECTOR = '[data-ipso-collapsible-box-title]';
const BOX_CONTENT_SELECTOR = '[data-ipso-collapsible-box-content]';
const BOX_ID_SELECTOR = (boxId) => `[data-ipso-collapsible-box-id="${boxId}"]`;
const BOX_ICON_SELECTOR = '[data-ipso-collapsible-box-icon]';

export default () => {
  const $boxTitles = $(BOX_TITLE_SELECTOR);
  const anchor = $(location).attr('hash');

  if (!$boxTitles.length) {
    return false;
  }

  function updateDisplay($boxTitle, isOpen, tl) {
    if (isOpen) {
      tl.restart().then(() => {
        $boxTitle.removeClass('w-full');
        $boxTitle.addClass('right-0');
      });
    } else {
      $boxTitle.addClass('w-full');
      tl.reverse().then(() => {
        $boxTitle.removeClass('right-0');
      });
    }
  }

  $boxTitles.each(function () {
    const $boxTitle = $(this);

    if (!$boxTitle.attr('data-ipso-collapsible-box-init')) {
      const boxId = $boxTitle.attr('data-ipso-collapsible-box-id');
      const id = $boxTitle.attr('id');
      const $boxContent = $(BOX_CONTENT_SELECTOR + BOX_ID_SELECTOR(boxId));

      const tl = gsap.timeline({ paused: true });
      const ease = 'power2.inOut';

      tl.to(
        $boxTitle.find(BOX_ICON_SELECTOR),
        {
          duration: 0.5,
          rotate: 180,
          ease,
        },
        0
      ).to(
        $boxContent,
        {
          duration: 0.5,
          height: 'auto',
          ease,
        },
        0
      );

      let isOpen = anchor && `#${id}` === anchor;

      $boxTitle.on('click', () => {
        isOpen = !isOpen;
        updateDisplay($boxTitle, isOpen, tl);
      });

      $boxTitle.attr('data-ipso-collapsible-box-init', true);
      updateDisplay($boxTitle, isOpen, tl);
    }
  });
};

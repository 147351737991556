<template>
  <div>
    <div class="flex w-full content-center items-start gap-1">
      <div
        class="h-2 w-12 rounded-lg border border-gray"
        :class="strength > 0 ? classColor : defaultBackgroundColor"
      ></div>
      <div
        class="h-2 w-12 rounded-lg border border-gray"
        :class="strength > 1 / 5 ? classColor : defaultBackgroundColor"
      ></div>
      <div
        class="h-2 w-12 rounded-lg border border-gray"
        :class="strength > 2 / 5 ? classColor : defaultBackgroundColor"
      ></div>
      <div
        class="h-2 w-12 rounded-lg border border-gray"
        :class="strength > 3 / 5 ? classColor : defaultBackgroundColor"
      ></div>
      <div
        class="h-2 w-12 rounded-lg border border-gray"
        :class="strength > 4 / 5 ? classColor : defaultBackgroundColor"
      ></div>
    </div>
    <span v-if="textInformation" class="mt-2 text-base"
      >sécurité : {{ textInformation }}</span
    >
  </div>
</template>
<script>
const securityInformation = {
  VERY_STRONG: 'très forte',
  STRONG: 'forte',
  MEDIUM: 'moyenne',
  LOW: 'faible',
  VERY_LOW: 'très faible',
};
const securityColor = {
  VERY_STRONG: 'bg-green',
  STRONG: 'bg-blue',
  MEDIUM: 'bg-yellow',
  LOW: 'bg-orange',
  VERY_LOW: 'bg-red',
};

export default {
  components: {},
  props: {
    strength: Number,

    defaultBackgroundColor: {
      type: String,
      default: 'bg-white',
    },
  },

  computed: {
    textInformation: function () {
      if (this.strength) {
        if (this.strength > 4 / 5) {
          return securityInformation.VERY_STRONG;
        }
        if (this.strength > 3 / 5) {
          return securityInformation.STRONG;
        }
        if (this.strength > 2 / 5) {
          return securityInformation.MEDIUM;
        }
        if (this.strength > 1 / 5) {
          return securityInformation.LOW;
        }
        if (this.strength > 0) {
          return securityInformation.VERY_LOW;
        }
        return null;
      }
      return null;
    },
    classColor: function () {
      if (this.strength) {
        if (this.strength > 4 / 5) {
          return securityColor.VERY_STRONG;
        }
        if (this.strength > 3 / 5) {
          return securityColor.STRONG;
        }
        if (this.strength > 2 / 5) {
          return securityColor.MEDIUM;
        }
        if (this.strength > 1 / 5) {
          return securityColor.LOW;
        }
        if (this.strength > 0) {
          return securityColor.VERY_LOW;
        }
        return null;
      }
      return null;
    },
  },
};
</script>

<template>
  <Teleport to="#ipso-modals">
    <Transition :css="false" appear @enter="onEnter" @leave="onLeave">
      <div
        v-if="shouldShow"
        class="
          fixed
          inset-0
          z-20
          flex
          h-full
          w-full
          flex-col
          justify-center
          overflow-auto
          bg-blue/50
          opacity-0
          backdrop-blur-md
          lg:h-auto lg:w-auto
        "
      >
        <dialog
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-title"
          class="
            fixed
            mx-auto
            flex
            max-h-[100svh] max-w-screen-lg
            translate-y-[10%]
            flex-col
            overflow-y-hidden
            rounded-lg
            bg-white
            p-0
            opacity-0
            lg:max-h-[640px]
          "
          :class="{ 'w-full': !fitToContent }"
        >
          <header
            class="
              z-1
              flex
              w-full
              items-center
              justify-between
              p-3
              shadow-md
              lg:p-4
            "
          >
            <h4
              id="modal-title"
              class="
                grow
                pr-4
                text-left text-large
                font-bold
                text-blue
                lg:text-center
              "
            >
              <slot name="header" />
            </h4>

            <button
              type="button"
              class="
                flex
                h-10
                w-10
                shrink-0
                items-center
                justify-center
                rounded-lg
                p-2
              "
              @click="clickToClose"
            >
              <svg-icon class="h-4 w-4 fill-orange" name="icon-close" />
            </button>
          </header>
          <div
            class="overflow-y-auto rounded-b-lg p-3 lg:m-0 lg:rounded-none"
            :class="containerClass"
          >
            <slot />
          </div>
        </dialog>
      </div>
    </Transition>
  </Teleport>
</template>

<script>
import gsap from 'gsap';

import SvgIcon from '@style-guide/base/svg_icon/SvgIcon.vue';
import { addEventListener } from '@util/dom';

import { mapState } from 'vuex';

import { mediaQueryNamespace } from '@util/media-query-plugin';

export default {
  components: {
    SvgIcon,
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },

    fitToContent: {
      type: Boolean,
      default: false,
    },

    containerClass: {
      type: String,
      default: 'p-3 bg-white',
    },
  },

  emits: ['has-opened', 'has-closed', 'close-clicked'],

  data() {
    return {
      shouldShow: this.isOpen,
      escEventListener: false,
      scrollY: 0,
      attrEventListener: false,
    };
  },

  computed: {
    ...mapState(mediaQueryNamespace, {
      isLg: (state) => state.lg,
      viewPortHeight: (state) => state.viewPortHeight,
    }),
  },

  watch: {
    isOpen(isOpen) {
      if (isOpen) {
        this.scrollY = window.scrollY;
        this.shouldShow = true;

        if (!this.attrEventListener) {
          this.attrEventListener = this.addAttrEventListener();
        }
      } else {
        this.shouldShow = false;
      }
    },

    viewPortHeight(viewPortHeight) {
      document.documentElement.style.setProperty(
        '--window-inner-height',
        `${viewPortHeight}px`
      );
    },
  },

  mounted() {
    this.escEventListener = window.addEventListener('keydown', (e) => {
      if (this.isOpen && e.key === 'Escape') {
        this.close();
      }
    });

    document.documentElement.style.setProperty(
      '--window-inner-height',
      `${this.viewPortHeight}px`
    );
  },

  beforeUnmount() {
    window.removeEventListener('keypress', this.escEventListener);

    if (this.attrEventListener) {
      window.removeEventListener('click', this.attrEventListener);
    }
  },

  methods: {
    onEnter: async function (el, done) {
      const overlay = el;
      const modal = el.querySelector('dialog');

      if ('showModal' in modal) {
        modal.showModal();
      }

      await gsap.to(overlay, {
        duration: 0.15,
        opacity: 1,
        ease: 'power2.inOut',
      });

      await gsap.to(modal, {
        duration: 0.3,
        opacity: 1,
        translateY: '0%',
        ease: 'power2.inOut',
      });

      this.toggleBodyClass(true);

      this.$emit('has-opened');

      window.IPSO_GLOBALS.initCollapsibleBox();

      done();
    },

    onLeave: async function (el, done) {
      const overlay = el;
      const modal = el.querySelector('dialog');

      this.toggleBodyClass(false);

      if ('close' in modal) {
        modal.close();
      }

      await gsap.to(modal, {
        duration: 0.3,
        opacity: 0,
        translateY: '10%',
        ease: 'power2.inOut',
      });

      await gsap.to(overlay, {
        duration: 0.15,
        opacity: 0,
        ease: 'power2.inOut',
      });

      this.$emit('has-closed');

      done();
    },

    clickToClose() {
      this.$emit('close-clicked');

      this.close();
    },

    close() {
      this.shouldShow = false;
    },

    toggleBodyClass(isOpen) {
      if (isOpen) {
        document.documentElement.classList.add('has-open-modal');
      } else {
        document.documentElement.classList.remove('has-open-modal');

        window.scrollTo(0, this.scrollY);
      }
    },

    addAttrEventListener() {
      return addEventListener(
        window,
        'click',
        () => {
          if (this.isOpen) {
            this.clickToClose();
          }
        },
        '[data-ipso-modal-close]'
      );
    },
  },
};
</script>

import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import duration from 'dayjs/plugin/duration';

export const ipsoFormatPlugin = (option, dayjsClass) => {
  dayjsClass.prototype.ispoLongFormat = function () {
    const ispoLongFormat = dayjsClass.prototype.format.bind(this)(
      'dddd D MMMM [à] HH[h]mm'
    );

    return ispoLongFormat;
  };
};

dayjs.locale('fr');
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(ipsoFormatPlugin);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(duration);

dayjs.tz.setDefault('Europe/Paris');

export default dayjs;

import $ from 'jquery';

const PAGE_SELECT_SELECTOR = '[data-ipso-page-select]';
const PAGE_SELECTOR = '[data-ipso-page]';
const PAGE_SELECT_ID_SELECTOR = (id) => `[data-ipso-page-select-id="${id}"]`;

export default () => {
  const $pageSelects = $(PAGE_SELECT_SELECTOR);

  if (!$pageSelects.length) {
    return false;
  }

  const anchor = $(location).attr('hash');
  const current_url = new URL(location.href);
  const searchParams = current_url.searchParams;

  function displayPage($pages, pageId) {
    $pages.each(function () {
      const $page = $(this);
      if ($page.attr('data-ipso-page-id') === pageId) {
        $page.removeClass('hidden');
      } else {
        $page.addClass('hidden');
      }
    });
  }

  function selectPageFromAnchor($pageSelect, $pages, anchor) {
    $pages.each(function () {
      const $page = $(this);
      if ($page.find(anchor).length > 0) {
        const pageId = $page.attr('data-ipso-page-id');
        $pageSelect.val(pageId);
        return false;
      }
    });
  }

  $pageSelects.each(function () {
    const $pageSelect = $(this);
    const pageSelectId = $pageSelect.attr('data-ipso-page-select-id');
    const $pages = $(PAGE_SELECTOR + PAGE_SELECT_ID_SELECTOR(pageSelectId));

    $pageSelect.on('input', () => {
      const pageId = $(this).val();
      current_url.searchParams.set(pageSelectId, pageId);
      history.pushState({}, '', current_url);
      displayPage($pages, pageId);
    });

    if (searchParams.has(pageSelectId)) {
      $pageSelect.val(searchParams.get(pageSelectId));
    } else if (anchor) {
      selectPageFromAnchor($pageSelect, $pages, anchor);
    }

    displayPage($pages, $pageSelect.val());
  });
};

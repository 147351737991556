import $ from 'jquery';

import { FIELD_VALIDATION_EVENT } from './field-validation';
export const SELECTOR = 'data-ipso-split-date-field';

export default () => {
  const $fields = $(`[${SELECTOR}]`);

  if (!$fields.length) {
    return false;
  }

  $fields.each((i, el) => new SplitDateField(el));
};

export class SplitDateField {
  constructor(el) {
    this.$el = $(el);

    this.$dayField = this.$el.find(`[${SELECTOR}-day]`);
    this.$monthField = this.$el.find(`[${SELECTOR}-month]`);
    this.$yearField = this.$el.find(`[${SELECTOR}-year]`);

    this.$el.on('focusout', () => {
      window.setTimeout(() => {
        if (!this.hasFieldFocused()) {
          const value = this.value();

          if (value) {
            this.$el.trigger(FIELD_VALIDATION_EVENT, [value, this.$el[0].id]);
          }
        }
      }, 100);
    });
  }

  hasFieldFocused() {
    if (document.activeElement === this.$dayField[0]) {
      return true;
    }

    if (document.activeElement === this.$monthField[0]) {
      return true;
    }

    if (document.activeElement === this.$yearField[0]) {
      return true;
    }

    return false;
  }

  value() {
    let day = this.$dayField.val();
    let month = this.$monthField.val();
    const year = this.$yearField.val();

    if (day && month && year) {
      if (day.length === 1) {
        day = `0${day}`;
      }

      if (month.length === 1) {
        month = `0${month}`;
      }

      return `${year}-${month}-${day}`;
    }

    return null;
  }
}

import { nanoid } from 'nanoid';
import { createPopper } from '@popperjs/core';

export class Tooltip {
  constructor(
    elem,
    content,
    options = { noDecoration: false, longText: false }
  ) {
    this.id = nanoid();
    this.button = elem;

    if (!options.noDecoration) {
      this.button.classList.add(
        'border-dotted',
        'border-b',
        'border-b-gray-dark',
        'cursor-help'
      );
    }

    this.button.setAttribute('aria-describedby', this.id);

    // create tooltip element
    this.tooltip = document.createElement('div');

    this.tooltip.id = this.id;
    this.tooltip.setAttribute('role', 'tooltip');

    this.tooltip.classList.add(
      'hidden',
      'block',
      'bg-gray-darker',
      'text-base',
      'text-white',
      'py-2',
      'px-4',
      'rounded-md',
      'shadow-lg',
      'max-w-md',
      'z-20'
    );

    if (!options.longText) {
      this.tooltip.classList.add('font-semibold');
    }

    this.tooltip.innerHTML = content;

    const arrow = document.createElement('div');

    arrow.setAttribute('data-popper-arrow', true);

    arrow.classList.add(
      'absolute',
      'h-2',
      'w-2',
      'before:content-[""]',
      'before:rotate-45',
      'before:bg-gray-darker',
      'before:block',
      'before:h-2',
      'before:w-2'
    );

    this.tooltip.append(arrow);

    this.button.after(this.tooltip);

    // init popper
    this.popperInstance = createPopper(this.button, this.tooltip, {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    });

    this.isShowing = false;

    // handle events
    this.showEvents = ['mouseenter', 'focus'];
    this.hideEvents = ['mouseleave', 'blur'];

    this.showEvents.forEach((event) => {
      this.button.addEventListener(event, this.show.bind(this));
    });

    this.hideEvents.forEach((event) => {
      this.button.addEventListener(event, this.hide.bind(this));
    });

    this.escEventListener = window.addEventListener('keydown', ({ key }) => {
      if (this.isShowing && key === 'Escape') {
        this.hide();
      }
    });
  }

  show() {
    this.isShowing = true;

    this.tooltip.classList.remove('hidden');

    this.popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [
        ...options.modifiers,
        { name: 'eventListeners', enabled: true },
      ],
    }));

    this.popperInstance.update();
  }

  hide() {
    this.isShowing = false;

    this.tooltip.classList.add('hidden');

    this.popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [
        ...options.modifiers,
        { name: 'eventListeners', enabled: false },
      ],
    }));
  }

  destroy() {
    this.showEvents.forEach((event) => {
      this.button.removeEventListener(event, this.show);
    });

    this.hideEvents.forEach((event) => {
      this.button.removeEventListener(event, this.hide);
    });

    window.removeEventListener('keydown', this.escEventListener);

    this.tooltip.remove();
  }
}

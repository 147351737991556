import $ from 'jquery';

const DEPENDING_ON_SELECTOR = '[data-ipso-show-depending-on]';

// TODO:
//  - use smooth transition

export default () => {
  function update(shouldShow, showAll, hideAll) {
    if (shouldShow) {
      showAll();
    } else {
      hideAll();
    }
  }

  function shouldShow($itemDependingOn, $dependencyItem) {
    const dependencyValue = $dependencyItem.val();

    const itemEqValue = $itemDependingOn.attr(
      'data-ipso-show-depending-on-value-is'
    );
    if (itemEqValue !== undefined) {
      return itemEqValue === dependencyValue;
    }

    const itemNotEqValue = $itemDependingOn.attr(
      'data-ipso-show-depending-on-value-is-not'
    );
    if (itemNotEqValue !== undefined) {
      return itemNotEqValue !== dependencyValue;
    }

    const itemInValues = $itemDependingOn.attr(
      'data-ipso-show-depending-on-value-in'
    );
    if (itemInValues !== undefined) {
      const values = itemInValues.split(',');
      return values.includes(dependencyValue);
    }

    const itemNotInValues = $itemDependingOn.attr(
      'data-ipso-show-depending-on-value-not-in'
    );
    if (itemNotInValues !== undefined) {
      const values = itemNotInValues.split(',');
      return !values.includes(dependencyValue);
    }

    return false;
  }

  const $itemsDependingOn = $(DEPENDING_ON_SELECTOR);

  if (!$itemsDependingOn.length) {
    return false;
  }

  $itemsDependingOn.each(function () {
    const $item = $(this);

    if (!$item.attr('data-ipso-show-depending-on-init')) {
      const dependencyId = $item.attr('data-ipso-show-depending-on');
      const $dependency = $('#' + dependencyId);
      const itemId = $item.attr('id');
      const $itemLabel = $(`label[for="${itemId}"]`);
      const $itemErrors = $(
        `[data-ipso-field-validation-errors-for="${itemId}"]`
      );
      const $itemFieldBlock = $item.parent(['data-ipso-field-validation']);

      const showAll = () => {
        $item.show();
        $itemLabel.show();
        $itemErrors.show();
        $itemFieldBlock.show();
      };

      const hideAll = () => {
        $itemFieldBlock.hide();
        $itemErrors.hide();
        $itemLabel.hide();
        $item.hide();
      };

      update(shouldShow($item, $dependency), showAll, hideAll);
      $dependency.on('input', () => {
        update(shouldShow($item, $dependency), showAll, hideAll);
      });
      $item.attr('data-ipso-show-depending-on-init', true);
    }
  });
};

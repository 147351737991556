import $ from 'jquery';
import gsap from 'gsap';

const MESSAGE_SELECTOR = '[data-ipso-message]';

export default () => {
  const $messages = $(MESSAGE_SELECTOR);

  if (!$messages.length) {
    return false;
  }

  $messages.each(function () {
    const $message = $(this);

    if (!$message.is('[data-ipso-message-permanent]')) {
      const duration = parseInt($message.attr('data-ipso-message-duration'));

      setTimeout(function () {
        gsap.to($message.get(), {
          duration: 0.3,
          height: 0,
          opacity: 0,
          ease: 'power2.inOut',
        });
      }, duration);
    }
  });
};

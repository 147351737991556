import $ from 'jquery';
export const FIELD_VALIDATION_SELECTOR = 'data-ipso-field-password-display';

export default () => {
  const $fields = $(`[${FIELD_VALIDATION_SELECTOR}]`);

  if (!$fields.length) {
    return false;
  }
  $fields.click(() => {
    const $parent = $fields.parent();
    if ($parent.find('input:password').length) {
      $parent.find('input:password').attr('type', 'text');
    } else {
      $parent.find('input:text').attr('type', 'password');
    }
    $fields.find('svg').toggle();
    return false;
  });
};

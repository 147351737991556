import $ from 'jquery';
import Form from '@api/form';
import debounce from 'lodash.debounce';

export const FIELD_VALIDATION_SELECTOR = 'data-ipso-field-validation';
export const FIELD_VALIDATION_EVENT = 'ipso-field-validate';

export default () => {
  const $fields = $(`[${FIELD_VALIDATION_SELECTOR}]`);

  if (!$fields.length) {
    return false;
  }

  const fieldsWithValidation = [];

  $fields.each((i, el) =>
    fieldsWithValidation.push(new FieldWithValidation(el))
  );

  const erroredFields = fieldsWithValidation.filter((field) =>
    field.hasError()
  );

  if (erroredFields.length) {
    erroredFields[0].focus();

    erroredFields.forEach((erroredField) => erroredField.correctErroredField());
  }
};

// FIXME
// this code will hide/erase server-side errors that can only be detected by global form clean
// (and not by isolated field clean)

export class FieldWithValidation {
  constructor(el) {
    this.$wrapper = $(el);
    this.$input = this.$wrapper.find('select, input, textarea');
    this.$errors = this.$wrapper.find('[data-ipso-field-validation-errors]');
    this.$label = this.$wrapper.find('label');

    this.validationType = this.$wrapper.attr(FIELD_VALIDATION_SELECTOR);

    if (this.validationType === 'auto') {
      this.$input.on('focusout', (event) => {
        // case if patient click on a link when it is on a form (link present on error for example)
        if (!$(event.relatedTarget).is('a')) {
          this.validate(this.$input.val(), this.$input.prop('id'));
        }
      });
    } else if (this.validationType === 'manual') {
      this.$wrapper.on(FIELD_VALIDATION_EVENT, (event, value, id) => {
        this.validate(value, id);
      });
    }
  }

  focus() {
    this.$input[0].focus();
  }

  hasError() {
    return this.$wrapper.hasClass('text-red');
  }

  validate = async function (value, id) {
    const [response, error] = await Form.validateField({
      value,
      id,
      path: window.location.pathname,
    });

    if (error) {
      return;
    }

    const { status, ...validationData } = response;

    if (status === true) {
      this.onSuccess(validationData);
    } else {
      this.onError(validationData);
    }

    return status;
  };

  reset() {
    this.$wrapper.removeClass('text-red');
    this.$errors.empty();
  }

  onSuccess() {
    this.reset();
  }

  correctErroredField() {
    if (!this.validationType === 'auto' || this.isCorrectingErroredField) {
      return false;
    }

    this.isCorrectingErroredField = true;

    this.debouncedErrorCheck = debounce(async () => {
      const isValid = await this.validate(
        this.$input.val(),
        this.$input.prop('id')
      );

      if (isValid) {
        this.$input.off('input', this.debouncedErrorCheck);
        this.isCorrectingErroredField = false;
      }
    }, 1000);

    this.$input.on('input', this.debouncedErrorCheck);
  }

  onError({ messages }) {
    this.reset();
    this.$wrapper.addClass('text-red');

    messages.forEach((message) => {
      this.$errors.append(`<span class="text-red block">${message}</span>`);
    });

    this.correctErroredField();
  }
}

<template>
  <password-strength :strength="strength"></password-strength>
</template>

<script>
import PasswordStrength from '@style-guide/components/password_strength/PasswordStrength';
import Form from '@api/form';
export default {
  components: { PasswordStrength },
  props: {
    targetPasswordId: String,
  },
  data: function () {
    return {
      currentPassword: null,
      strength: Number.NaN,
    };
  },
  computed: {
    targetPasswordInput: function () {
      return $(`input:password[id=${this.targetPasswordId}]`);
    },
  },

  watch: {
    currentPassword: function () {
      this.updateStrength();
    },
  },
  mounted() {
    this.updateCurrentPassword();
    this.targetPasswordInput
      .change(this.updateCurrentPassword)
      .keyup(this.updateCurrentPassword);
  },
  methods: {
    updateCurrentPassword: function () {
      if (this.targetPasswordInput.val()) {
        this.currentPassword = this.targetPasswordInput.val();
      }
    },
    updateStrength: async function () {
      if (this.currentPassword) {
        const [response, error] = await Form.passwordStrength({
          password: this.currentPassword,
        });
        if (error) {
          this.strength = Number.NaN;
        }
        this.strength = Number.parseFloat(response.strength);
      }
    },
  },
};
</script>

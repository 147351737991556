import localStorageTtl from '@util/local-storage-ttl';
import dayjs from '@util/dayjs';

function isBrowserTimeSameAsParis() {
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const browserTime = dayjs.tz(new Date(), browserTimezone);
  const parisTime = dayjs.tz(new Date());

  return (
    browserTime.format('YYYY-MM-DD HH:mm') ===
    parisTime.format('YYYY-MM-DD HH:mm')
  );
}

function checkTimezone() {
  if (!isBrowserTimeSameAsParis()) {
    const hasShownTimezoneNotification = localStorageTtl.get(
      'has_shown_timezone_notification'
    );

    if (!hasShownTimezoneNotification) {
      window.IPSO_GLOBALS.addToastMessage({
        type: 'warning',
        messages: [
          "Attention, vous naviguez sur un appareil qui n'est pas configuré sur le fuseau horaire de Paris.",
          'Tous les horaires affichés sur ce site (les créneaux, les rendez-vous) sont sur ce fuseau horaire 🇫🇷 🕗',
        ],
        action: {
          text: "j'ai compris",
          callback: () => {
            localStorageTtl.set('has_shown_timezone_notification', true, {
              days: 7,
            });
          },
        },
      });
    }
  }
}

export default () => {
  const urls = ['agenda', 'mon-espace'];
  const { pathname } = window.location;

  for (const url of urls) {
    if (pathname.includes(url)) {
      checkTimezone();
    }
  }

  if (pathname === '/') {
    checkTimezone();
  }
};

import '../../../css/main.css';
import 'core-js/modules/es.array.at';

import $ from 'jquery';
import Cookies from 'js-cookie';

import Api from '@api/index';
import { withSentry } from '@util/sentry/with-sentry';
import { createVueUiApp } from '@util/vue-ui-app';

import ajaxModal from './AjaxModal/index';
import appointmentTunnelNav from './appointment-tunnel-nav';
import datepicker from './datepicker';
import fieldValidation from './field-validation';
import googleMap from './google-map';
import hideableFormSection from './hideable-form-section';
import { initTooltips } from './tooltip';
import messages from './messages';
import mobileNavigation from './mobile-navigation';
import pageSelect from './page-select';
import passwordStrength from './PasswordStrength/index';
import scrollNav from './scroll-nav';
import CollapsibleBox from './collapsible-box';
import splitDateField from './split-date-field';
import timezoneCheck from './timezone-check';
import browserCompatWarning from './browser-compat-warning';
import ToastMessages from '@style-guide/components/toast_messages/ToastMessages.vue';
import blockDoubleClickSubmit from './block-double-click-submit';
import showDependingOn from './show-depending-on';

import passwordDisplay from '@root/frontend/src/app/main/password-display';
import autoFieldFocus from '@root/frontend/src/app/main/autoFieldFocus';

$(() => {
  withSentry('main-ui', () => {
    const { sessionid } = Cookies.get();

    Api.init({
      serverUrlBase: window.location.origin,
      publicHostName: window.location.host,
      sessionId: sessionid,
    });

    window.IPSO_GLOBALS.initCollapsibleBox = CollapsibleBox;

    appointmentTunnelNav();
    autoFieldFocus();
    datepicker();
    fieldValidation();
    hideableFormSection();
    initTooltips();
    messages();
    mobileNavigation();
    pageSelect();
    passwordDisplay();
    passwordStrength();
    scrollNav();
    CollapsibleBox();
    splitDateField();
    blockDoubleClickSubmit();
    showDependingOn();

    createVueUiApp(ToastMessages);

    // dependent on toast messages
    browserCompatWarning();
    timezoneCheck();

    ajaxModal();
    googleMap();
  });
});

import { Tooltip } from '@style-guide/atoms/tooltip/Tooltip';

const DATA_ATTR = 'data-ipso-tooltip';

const tooltips = [];

export function initTooltips() {
  const elems = document.querySelectorAll(`[${DATA_ATTR}]`);

  elems.forEach((elem) => {
    if (!tooltips.includes(elem)) {
      const content = elem.dataset.ipsoTooltipContent;
      const noDecoration = 'ipsoTooltipNoDecoration' in elem.dataset;
      const longText = 'ipsoTooltipLongText' in elem.dataset;

      const tooltip = new Tooltip(elem, content, { noDecoration, longText });

      elem.tooltip = tooltip;

      tooltips.push(tooltip);
    }
  });
}

export function destroyAllTooltips() {
  tooltips.forEach((elem) => elem.tooltip.destroy());
}

import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

import { getConfig } from '@util/sentry/get-config';

window.__SENTRY__.hasInit = false;

export default {
  getInstance() {
    if (window.__SENTRY__.hasInit) {
      return Sentry;
    }

    const { PROJECT_ENVIRONMENT } = window.IPSO_GLOBALS;

    const sentryConfig = getConfig();

    const shouldSendEvents = PROJECT_ENVIRONMENT !== 'local';

    Sentry.init({
      ...sentryConfig,
      integrations: [
        new BrowserTracing(),
        new Sentry.Breadcrumbs({
          console: shouldSendEvents,
          sentry: true,
        }),
      ],
      debug: false,
      beforeSend(event) {
        if (!shouldSendEvents) {
          console.log('[Sentry] event: ', event);
        }
        if (shouldSendEvents) {
          return event;
        }

        return null;
      },
      beforeBreadcrumb(breadcrumb) {
        if (!shouldSendEvents) {
          console.log('[Sentry] breadcrumb: ', breadcrumb);
        }
        if (shouldSendEvents) {
          return breadcrumb;
        }
        return null;
      },
    });

    window.__SENTRY__.hasInit = true;

    return Sentry;
  },
};

import debounce from 'lodash.debounce';

import { IS_BROWSER } from '@util/globals';

export const mediaQueryNamespace = 'mediaQuery';

const mutationNames = {
  UPDATE_MEDIA_QUERIES: 'UPDATE_MEDIA_QUERIES',
  UPDATE_VIEWPORT_WIDTH: 'UPDATE_VIEWPORT_WIDTH',
  UPDATE_VIEWPORT_HEIGHT: 'UPDATE_VIEWPORT_HEIGHT',
};

export const setupMediaQueries = (store, serverSetViewport) => {
  // tailwind/resolveConfig is huge and not suitable for browser
  // https://tailwindcss.com/docs/configuration#referencing-in-java-script
  const screens = preval`
    const resolveConfig = require('tailwindcss/resolveConfig');
    const tailwindConfig = require('../../../tailwind.config');
    module.exports = resolveConfig(tailwindConfig).theme.screens;
  `;

  const result = {};

  for (const [name, width] of Object.entries(screens)) {
    if (!IS_BROWSER) {
      const widthAsInt = Number.parseInt(width.replace('px', ''));

      result[name] = serverSetViewport >= widthAsInt;
    } else {
      const mql = window.matchMedia(`(min-width: ${width})`);

      mql.addEventListener('change', (e) => {
        const { matches } = e;

        store.commit(
          `${mediaQueryNamespace}/${mutationNames.UPDATE_MEDIA_QUERIES}`,
          { name, matches }
        );
      });

      result[name] = mql.matches;
    }
  }

  return result;
};

export const installMediaQueryPlugin = (
  store,
  serverSetViewport = { height: undefined, width: undefined }
) => {
  const initialState = setupMediaQueries(store, serverSetViewport);

  const initialViewPortWidth = IS_BROWSER
    ? window.innerWidth
    : serverSetViewport.width;

  const initialViewPortHeight = IS_BROWSER
    ? window.innerHeight
    : serverSetViewport.height;

  store.registerModule(mediaQueryNamespace, {
    namespaced: true,
    state: {
      ...initialState,
      viewPortWidth: initialViewPortWidth,
      viewPortHeight: initialViewPortHeight,
    },
    mutations: {
      [mutationNames.UPDATE_MEDIA_QUERIES](state, { name, matches }) {
        state[name] = matches;
      },

      [mutationNames.UPDATE_VIEWPORT_WIDTH](state, viewPortWidth) {
        state.viewPortWidth = viewPortWidth;
      },

      [mutationNames.UPDATE_VIEWPORT_HEIGHT](state, viewPortHeight) {
        state.viewPortHeight = viewPortHeight;
      },
    },
  });

  if (IS_BROWSER) {
    window.addEventListener(
      'resize',
      debounce(() => {
        store.commit(
          `${mediaQueryNamespace}/${mutationNames.UPDATE_VIEWPORT_WIDTH}`,
          window.innerWidth
        );
        store.commit(
          `${mediaQueryNamespace}/${mutationNames.UPDATE_VIEWPORT_HEIGHT}`,
          window.innerHeight
        );
      }, 150)
    );
  }
};

import $ from 'jquery';
import dayjs from '@util/dayjs';

import { initDatepicker } from '@util/datepicker';

export const DATEPICKER_SELECTOR = 'data-ipso-datepicker';
import { FIELD_VALIDATION_EVENT } from './field-validation';

export default () => {
  const $datepicker = $(`[${DATEPICKER_SELECTOR}]`);

  if (!$datepicker.length) {
    return false;
  }

  $datepicker.each((i, elem) => {
    const id = elem.id;
    const max = elem.getAttribute('max');
    const min = elem.getAttribute('min');
    const dateFormat = elem.getAttribute(DATEPICKER_SELECTOR);
    initDatepicker({
      elem,
      dateFormat: dateFormat.length > 0 ? dateFormat : 'dd/MM/yyyy', // be careful the dateFormat of air datepicker use a different format than dayjs
      min,
      max,
      initialDateString: elem.value,
      onSelect(date, formattedDate) {
        elem.value = formattedDate;
        $(elem).trigger(FIELD_VALIDATION_EVENT, [elem.value, id]);
      },
      onShow(isFinished) {
        if (!isFinished) return false;

        const currentDate = dayjs(elem.value, 'DD/MM/YYYY');
        if (currentDate.isValid()) {
          elem.$datepicker.setViewDate(currentDate);
        }
      },
    });
    $(elem).keyup(() => {
      const currentDate = dayjs(elem.value, 'DD/MM/YYYY');
      if (currentDate.isValid()) {
        elem.$datepicker.selectDate(currentDate);
      }
    });
  });
};

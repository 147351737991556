import $ from 'jquery';

export default () => {
  /**
   * data-attr à utiliser sur les button et input type="submit"
   */
  $(document).on('submit', 'form', function () {
    const $form = $(this);
    const $button = $form.find('[data-ipso-block-double-submit]');

    if (!$button.length) {
      return true;
    }

    $button.attr('disabled', true);

    const $icon = $button.find('> svg');

    if ($icon.length) {
      $icon.removeClass('hidden');
    }
  });

  /**
   * data-attr à utiliser sur tout autre element cliquable
   */
  $(document).on('click', '[data-ipso-block-double-click]', function () {
    const $elem = $(this);

    $elem.attr('disabled', true);

    const $icon = $elem.find('> svg');

    if ($icon.length) {
      $icon.removeClass('hidden');
    }
  });
};

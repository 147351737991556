import $ from 'jquery';
import gsap from 'gsap';

const HIDEABLE_FORM_SECTION_SELECTOR = (component) =>
  `[data-ipso-hideable-form-section${component ? `-${component}` : ''}]`;
const HIDEABLE_FORM_SECTION_TOGGLE_SELECTOR =
  HIDEABLE_FORM_SECTION_SELECTOR('toggle');
const HIDEABLE_FORM_SECTION_CONTENT_SELECTOR =
  HIDEABLE_FORM_SECTION_SELECTOR('content');
const HIDEABLE_FORM_SECTION_ICON_SELECTOR =
  HIDEABLE_FORM_SECTION_SELECTOR('icon');
const HIDEABLE_FORM_SECTION_LABEL_SELECTOR =
  HIDEABLE_FORM_SECTION_SELECTOR('label');

export default () => {
  const $hideableFormSections = $(HIDEABLE_FORM_SECTION_SELECTOR());
  const anchor = $(location).attr('hash');

  if (!$hideableFormSections.length) {
    return false;
  }

  function updateDisplay(isOpen, tl) {
    if (isOpen) {
      tl.restart();
    } else {
      tl.reverse();
    }
  }

  function toggleFields($fields, isOpen) {
    if (isOpen) {
      $fields.each((i, field) => {
        $(field).attr('disabled', false);
      });
    } else {
      $fields.each((i, field) => {
        $(field).attr('disabled', true);
      });
    }
  }

  $hideableFormSections.each(function () {
    const $hideableFormSection = $(this);

    if (!$hideableFormSection.attr('is-init')) {
      const id = $hideableFormSection.attr('id');
      const $toggle = $hideableFormSection.find(
        HIDEABLE_FORM_SECTION_TOGGLE_SELECTOR
      );
      const $content = $hideableFormSection.find(
        HIDEABLE_FORM_SECTION_CONTENT_SELECTOR
      );
      const $icon = $hideableFormSection.find(
        HIDEABLE_FORM_SECTION_ICON_SELECTOR
      );
      const $label = $hideableFormSection.find(
        HIDEABLE_FORM_SECTION_LABEL_SELECTOR
      );

      const $fields = $content.find('input, select, textarea');

      const tl = gsap.timeline({ paused: true });
      const ease = 'power2.inOut';

      tl.to(
        $content,
        {
          duration: 0.5,
          height: 'auto',
          ease,
        },
        0
      )
        .to(
          $icon,
          {
            duration: 0.5,
            rotate: 180,
            ease,
            opacity: 1,
          },
          0
        )
        .to(
          $label,
          {
            duration: 0.5,
            translateX: '-2em',
          },
          0
        );

      let isOpen = anchor && `#${id}` === anchor;

      toggleFields($fields, isOpen);

      $toggle.on('click', () => {
        isOpen = !isOpen;
        toggleFields($fields, isOpen);
        updateDisplay(isOpen, tl);
      });

      $hideableFormSection.attr('is-init', true);
      updateDisplay(isOpen, tl);
    }
  });
};

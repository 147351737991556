import Sentry from './index';

/**
 *
 * @param {string} appName to be passed to Sentry.captureException as tag
 * @param {function} app
 */
export const withSentry = async (appName, app) => {
  const sentry = Sentry.getInstance();

  try {
    await app();
  } catch (error) {
    console.error(error);

    sentry.withScope((scope) => {
      scope.setTag('app', appName);

      sentry.captureException(error);
    });
  }
};

import { createVueUiApp } from '@util/vue-ui-app';
import { installMediaQueryPlugin } from '@util/media-query-plugin';

import { createStore as createVueStore } from 'vuex';
import GoogleMap from '@style-guide/components/map/GoogleMap';

export default () => {
  //FIXME: use lazy loading when we find the issue with it
  createVueUiApp(GoogleMap, {
    selector: '[data-ipso-google-map]',
    getProps: (elem) => {
      const propsElemId = elem.getAttribute('data-ipso-google-map-props-id');
      return propsElemId
        ? JSON.parse(document.getElementById(propsElemId).textContent)
        : {};
    },
    createStore: () => {
      const store = createVueStore({});
      installMediaQueryPlugin(store, 1024);
      return store;
    },
    shouldAppendToBody: false,
  });
};

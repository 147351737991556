import AirDatepicker from 'air-datepicker';
import localeFr from 'air-datepicker/locale/fr';
import dayjs from '@util/dayjs';

export function initDatepicker({
  elem,
  container,
  initialDateString,
  min,
  max,
  dateFormat,
  onSelect,
  ...otherParams
}) {
  const isMobile = window.IPSO_GLOBALS?.DEVICE_INFO?.is_mobile;

  const params = {
    isMobile,
    dateFormat,
    autoClose: true,
    locale: localeFr,
    container,
  };

  if (initialDateString) {
    const initialDate = dayjs(initialDateString, 'DD/MM/YYYY');
    if (initialDate.isValid()) {
      params['startDate'] = initialDate;
      params['selectedDates'] = [initialDate];
    }
  }

  if (max) {
    params['maxDate'] = max;
  }

  if (min) {
    params['minDate'] = min;
  }

  if (onSelect) {
    params['onSelect'] = ({ date, formattedDate }) => {
      onSelect(date, formattedDate);
    };
  }

  const airDatepickerInstance = new AirDatepicker(elem, {
    ...params,
    ...otherParams,
  });

  elem.$datepicker = airDatepickerInstance;

  return airDatepickerInstance;
}

<template>
  <div
    class="
      flex
      min-h-[56px]
      flex-col
      items-center
      gap-4
      rounded-lg
      p-4
      text-base text-black
      md:gap-8
    "
    :class="{
      'bg-red-light': isError,
      'bg-sky-light': isInfo,
      'bg-green-light': isSuccess,
      'bg-yellow-light': isWarning,
    }"
  >
    <div class="flex flex-col items-center gap-2 md:flex-row">
      <svg-icon
        v-if="displayIcon"
        class="h-6 w-6 shrink-0"
        :class="svgIconClassByMessageType"
        :name="iconSymbol"
      ></svg-icon>
      <div :class="textClassByMessageType">
        <slot />
      </div>
    </div>
    <slot name="footer" />
  </div>
</template>

<script>
import SvgIcon from '@style-guide/base/svg_icon/SvgIcon.vue';

export default {
  components: {
    SvgIcon,
  },
  props: {
    customIcon: {
      required: false,
      type: String,
    },
    customIconClass: {
      required: false,
      type: String,
    },
    displayIcon: {
      default: false,
      type: Boolean,
    },
    opacityClass: {
      default: null,
      type: String,
    },
    textClass: {
      default: '',
      type: String,
    },
    type: {
      default: 'info',
      type: String,
    },
  },

  computed: {
    isError: function () {
      return this.type === 'error';
    },
    isSuccess: function () {
      return this.type === 'success';
    },
    isInfo: function () {
      return this.type === 'info';
    },
    isWarning: function () {
      return this.type === 'warning';
    },
    isCustom: function () {
      return this.type === 'custom';
    },

    iconSymbol() {
      if (this.customIcon) {
        return this.customIcon;
      }

      const iconMapping = {
        error: 'icon-circled-cross',
        success: 'icon-circled-check',
        info: 'icon-info',
        warning: 'icon-warning',
      };

      return iconMapping[this.type];
    },

    svgIconClassByMessageType() {
      if (this.customIconClass) {
        return this.customIconClass;
      }

      const svgIconDefaultClass = {
        error: 'fill-red',
        success: 'fill-green',
        info: 'fill-sky',
        warning: 'fill-yellow',
      };

      return `${this.opacityClassByMessageType} ${
        svgIconDefaultClass[this.type]
      }`;
    },

    opacityClassByMessageType() {
      const opacityClassMapping = {
        error: 'opacity-80',
        success: 'opacity-75',
        info: 'opacity-75',
        warning: 'opacity-80',
      };
      if (this.isCustom && this.opacityClass === null) {
        return 'opacity-100';
      } else if (this.opacityClass === null) {
        return opacityClassMapping[this.type];
      } else {
        return this.opacityClass;
      }
    },

    textClassByMessageType() {
      return `${this.opacityClassByMessageType} ${this.textClass}`;
    },
  },
};
</script>

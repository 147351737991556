import { createApp } from 'vue';

import { attachErrorHandler } from '@sentry/vue';

import { IS_BROWSER } from '@util/globals';

export const createVueUiApp = (vueComponent, params = {}) => {
  const {
    selector = 'body',
    shouldAppendToBody = true,
    createStore = undefined,
    createRouter = undefined,
    createOnce = false,
    getProps = undefined,
  } = params;

  const elems = createOnce
    ? [document.querySelector(selector)]
    : document.querySelectorAll(selector);

  if (!elems.length) {
    return false;
  }

  let vc = vueComponent;

  const apps = [];

  const store = createStore ? createStore() : undefined;
  const router = createRouter ? createRouter() : undefined;

  elems.forEach((elem) => {
    const props = getProps ? getProps(elem) : {};

    const app = createApp(vc, props);

    if (store) {
      app.use(store);
    }

    if (router) {
      app.use(router);
    }

    if (IS_BROWSER) {
      attachErrorHandler(app, { attachProps: true, logErrors: true });
    }

    if (shouldAppendToBody) {
      const mountElem = document.createElement('div');

      document.body.appendChild(mountElem);

      app.mount(mountElem);
    } else {
      app.mount(elem);
    }

    apps.push(app);
  });

  return apps;
};

import gsap from 'gsap';

export default () => {
  const body = document.body;
  const mobileNav = document.querySelector('[data-ipso-mobile-nav]');

  if (!mobileNav) {
    return false;
  }

  const openButton = document.querySelector('[data-ipso-mobile-nav-open]');
  const closeButton = document.querySelector('[data-ipso-mobile-nav-close]');

  const logOutButton = document.querySelector('[data-ipso-mobile-nav-logout');

  if (openButton) {
    openButton.addEventListener('click', (e) => {
      e.preventDefault();

      toggleNav(true);
    });
  }

  if (closeButton) {
    closeButton.addEventListener('click', (e) => {
      e.preventDefault();

      toggleNav(false);
    });
  }

  if (logOutButton) {
    logOutButton.addEventListener('click', (e) => {
      e.preventDefault();

      const shouldLogout = window.confirm('Voulez-vous vous déconnecter ?');

      if (shouldLogout) {
        window.location.href = logOutButton.href;
      }
    });
  }

  function toggleNav(isOpen) {
    if (isOpen) {
      body.classList.add('overflow-hidden');

      gsap.to(mobileNav, {
        duration: 0.3,
        opacity: 1,
        translateX: 0,
        pointerEvents: 'auto',
        ease: 'power2.inOut',
      });
    } else {
      body.classList.remove('overflow-hidden');

      gsap.to(mobileNav, {
        duration: 0.3,
        opacity: 0,
        translateX: '-100%',
        pointerEvents: 'none',
        ease: 'power2.inOut',
      });
    }
  }
};

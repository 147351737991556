import dayjs from '@util/dayjs';

function setWithExpiry(key, value, durationObject) {
  if (typeof key !== 'string' || typeof durationObject !== 'object') {
    throw new Error('Invalid key or missing duration object');
  }

  const now = dayjs();
  // https://day.js.org/docs/en/durations/creating
  const ttl = dayjs.duration(durationObject);
  const expiry = now.add(ttl).valueOf();

  if (now.add(ttl).isSame(now)) {
    throw new Error('Invalid duration');
  }

  const item = {
    value,
    expiry,
  };

  try {
    localStorage.setItem(key, JSON.stringify(item));
  } catch (e) {
    console.error('Could not set item in localStorage:', e);
  }
}

function getWithExpiry(key) {
  try {
    const itemStr = localStorage.getItem(key);

    if (!itemStr) {
      return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      removeItem(key);
      return null;
    }

    return item.value;
  } catch (e) {
    console.error('Could not get item from localStorage:', e);
    return null;
  }
}

function removeItem(key) {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.error('Could not remove item from localStorage:', e);
  }
}

export default {
  get: getWithExpiry,
  set: setWithExpiry,
};

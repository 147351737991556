import Api from './index';

export const VALIDATE_FORM = 'frontend-api:form:validate-form';
export const VALIDATE_FIELD = 'frontend-api:form:validate-field';
export const PASSWORD_STRENGTH = 'frontend-api:form:password-strength';

const Form = {
  validateViaFormClass: async function ({ formClass, data }) {
    const [response, error] = await Api.postJsonByDjangoViewName({
      viewName: VALIDATE_FORM,
      params: {
        form_class: formClass,
      },
      data,
    });

    if (error) return [undefined, error];

    return [response, undefined];
  },

  validateField: async function ({ value, id, path }) {
    const [response, error] = await Api.postJsonByDjangoViewName({
      viewName: VALIDATE_FIELD,
      data: {
        value,
        id,
        path,
      },
    });

    if (error) return [undefined, error];

    return [response, undefined];
  },

  passwordStrength: async function ({ password }) {
    const [response, error] = await Api.postJsonByDjangoViewName({
      viewName: PASSWORD_STRENGTH,
      data: {
        password,
      },
    });

    if (error) return [undefined, error];

    return [response, undefined];
  },
};

export default Form;

/**
 * get config for Sentry from IPSO_GLOBALS
 */
export const getConfig = () => {
  const { SENTRY_DSN, PROJECT_ENVIRONMENT, PROJECT_RELEASE_VERSION } =
    window.IPSO_GLOBALS;

  // track 1% of events on prod
  let tracesSampleRate = 0.01;

  // only track 100% of events outside of prod
  if (['dev', 'beta'].includes(PROJECT_ENVIRONMENT)) {
    tracesSampleRate = 1.0;
  }

  // track 0% of events on local
  if (PROJECT_ENVIRONMENT === 'local') {
    tracesSampleRate = 0;
  }

  const sentryConfig = {
    dsn: SENTRY_DSN,
    environment: PROJECT_ENVIRONMENT,
    release: PROJECT_RELEASE_VERSION,
    logErrors: true,
    tracesSampleRate,
  };

  return sentryConfig;
};

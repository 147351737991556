import { createVueUiApp } from '@util/vue-ui-app';
import PasswordStrength from './PasswordStrength.vue';

export default () => {
  createVueUiApp(PasswordStrength, {
    selector: '[data-ipso-password-strength]',
    getProps: (elem) => ({
      targetPasswordId: elem.getAttribute('data-ipso-password-strength-for'),
    }),
    shouldAppendToBody: false,
  });
};

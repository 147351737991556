import { createVueUiApp } from '@util/vue-ui-app';
import { installMediaQueryPlugin } from '@util/media-query-plugin';

import { createStore } from 'vuex';
import AjaxModal from './AjaxModal.vue';

export default () => {
  createVueUiApp(AjaxModal, {
    selector: '[data-ipso-ajax-modal]',
    createOnce: true,
    createStore: () => {
      const store = createStore({});

      installMediaQueryPlugin(store, 1024);

      return store;
    },
  });
};

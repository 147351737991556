import $ from 'jquery';
import gsap from 'gsap';

const APPT_NAV_LIST = '[data-ipso-appt-nav-list]';
const APPT_NAV_BUTTON = '[data-ipso-appt-nav-button]';
const APPT_NAV_BUTTON_COUNTER = '[data-ipso-appt-nav-button-counter]';
const APPT_NAV_BUTTON_TEXT = '[data-ipso-appt-nav-button-text]';
const APPT_NAV_BUTTON_ICON = '[data-ipso-appt-nav-button-icon]';

export default () => {
  const $apptNavButton = $(APPT_NAV_BUTTON);
  const $apptNavList = $(APPT_NAV_LIST);

  if (!$apptNavButton.length) {
    return false;
  }

  const tl = gsap.timeline({ paused: true });
  const ease = 'power2.inOut';

  tl.to($apptNavButton.find(APPT_NAV_BUTTON_COUNTER), {
    duration: 0.5,
    opacity: 0,
    ease,
  })
    .to(
      $apptNavButton.find(APPT_NAV_BUTTON_TEXT),
      {
        duration: 0.5,
        opacity: 0,
        ease,
      },
      0
    )
    .to(
      $apptNavButton.find(APPT_NAV_BUTTON_ICON),
      {
        duration: 0.5,
        rotate: 180,
        ease,
      },
      0
    )
    .to(
      $apptNavList[0],
      {
        duration: 0.5,
        height: 'auto',
        opacity: 1,
        ease,
      },
      0
    );

  let isOpen = false;

  $apptNavButton.on('click', () => {
    isOpen = !isOpen;

    if (isOpen) {
      tl.restart().then(() => {
        $apptNavButton.removeClass('w-full');
        $apptNavButton.addClass('right-0');
      });
    } else {
      $apptNavButton.addClass('w-full');

      tl.reverse().then(() => {
        $apptNavButton.removeClass('right-0');
      });
    }
  });
};
